/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import './index.less'

const App = () => {
    return (
        <div className='downloadBox'>
            <div className="wrapBox">
                <main className="main">
                    <section className="left">
                        <div className="container">
                            <div className="left-box">
                                <img className="mr40" src="https://img.fanwoon.com/fanwood_web_2.png" />
                                <div className='mt5'>快速发布职位</div>
                            </div>
                            <div className="right-box">
                                <img src="https://img.fanwoon.com/fanwood_web_3.png" />
                                <div className='mt5'>在线邀约面试</div>
                            </div>
                        </div>
                    </section>
                    <section className="right">
                        <img className="logoBox" src="https://img.fanwoon.com/new_logo.png" />
                        <div className="store-slogan">商户招聘端</div>
                        <div>
                            <img className="mr20 img144" src="https://img.fanwoon.com/fanwood_web_4.png" />
                            <img className="img144" src="https://img.fanwoon.com/fanwood_web_5.png" />
                        </div>
                        <div className="store-discription">
                            「翻腕」是面向新蓝领人群的推荐求职招聘平台，由上海往矣科技有限公司于2020年推出。翻腕通过推荐招聘求职方式，不仅可以让门店快速的找到合适的员工，蓝领求职者也可以通过推荐好友、亲人求职，来获取真实丰厚的推荐奖金。
                        </div>
                        <div className='pd10'>如果您在使用中遇到任何问题，可关注翻腕公众号与我们联系</div>
                        <div>
                            <img className="contanct-pic" src="https://img.fanwoon.com/contanct_pic.png" />
                        </div>
                    </section>
                </main>
                <footer className="footer">
                    <span>Copyright © 2025 翻腕</span>
                    <a href='https://beian.miit.gov.cn/'>沪ICP备2020029704号-2</a>
                    <span>
                        <img alt="" className="beian-icon" src="https://img.fanwoon.com/police.png" /><a target="_blank" className="normal-a" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011302007180">沪公网安备 31011302007180号</a>
                    </span>
                </footer>
            </div>
        </div>
    )
}

export default App