import React from 'react'
import './index.less'

const App = () => {
    return (
        <div className='flai-wrap'>
            {/* <div className="wrapBox">
                <main className="main">
                   
                    
                </main>
                <footer className="footer">
                    <span>Copyright © 2025 翻腕</span>
                    <a href='https://beian.miit.gov.cn/'>沪ICP备2020029704号-2</a>
                    <span>
                        <img alt="" className="beian-icon" src="https://img.fanwoon.com/police.png" /><a target="_blank" className="normal-a" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011302007180">沪公网安备 31011302007180号</a>
                    </span>
                </footer>
            </div> */}
        </div>
    )
}

export default App